.header {
    background-color: $color-main;
    position: relative;

    &--fixed {
        top: 0;
        width: 100%;
        position: absolute;
        background-color: $color-main;
    }
    @media (max-width: $screen-md) {
        &--mobile-fixed {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 3;
        }
    }

    &__row {
        min-height: 80px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        display: flex;

        &--mobile {
            display: none;
        }

        @media (max-width: $screen-md) {
            min-height: 57px;

            &--mobile {
                display: flex;
                span {
                    width: 100%;
                    text-align: center;
                }
                & + .header__row {
                    display: none;
                }
            }
        }
    }

    &__logo {
        width: 100%;
        max-width: 261px;
        margin-right: 40px;
        height: 29px;
        img {
            width: auto;
            height: 100%;
        }
        @media (max-width: $screen-xl) {
            width: auto;
        }
        @media (max-width: $screen-md) {
            margin-right: 30px;
            height: 24px;
        }
        @media (max-width: $screen-xs) {
            height: 14px;
        }
    }

    &__menu {
        width: 100%;
        max-width: 600px;
        display: flex;
        justify-content: space-between;

        & .active {
            .header__menu-link {
                &-text {
                    color: $color-main--hover;
                }

                &-icon {
                    path {
                        fill: $color-main--hover;
                    }
                }
            }
        }

        &-link {
            display: flex;
            align-items: center;
            color: #D2D2D2;
            text-decoration: none;
            position: relative;

            &-notify {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: red;
                color: $color-white;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                font-size: 12px;
                top: -5px;
                left: 100%;
            }

            &-popup {
                opacity: 0;
                width: 100%;
                min-width: 200px;
                min-height: 380px;
                visibility: hidden;
                position: absolute;
                top: 100%;
                margin-top: 16px;
                background: rgba(7, 10, 15, 0.1);
                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
                backdrop-filter: blur(70px);
                z-index: 10;
                /* Note: backdrop-filter has minimal browser support */
                transition: all .2s ease;
                border-radius: 10px;
                padding: 20px;
                opacity: 0;
                overflow-y: scroll;
                @include scrollbars(0px, $color-main, #2E3D5C);

                &--show {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &-icon {
                margin-right: 12px;

                *[fill="#D2D2D2"], *[fill="#A4A4A4"] {
                    fill: currentColor;
                }

                *[stroke="#D2D2D2"] {
                    stroke: currentColor;
                }
            }

            &-text {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                color: $color-white;
            }

            &:hover {
                color: $color-white;
            }
            @media (max-width: $screen-lg) {
                &-text {
                    display: none;
                }
            }
        }

        @media (max-width: $screen-md) {
            max-width: 270px;
        }

        @media (max-width: $screen-sm) {
            display: none;
        }
    }

    &__auth {
        display: flex;
        margin-left: 20px;
        min-width: 250px;
        justify-content: flex-end;
        @media (max-width: $screen-xl) {
            min-width: 200px;
        }

        @media (max-width: $screen-lg) {
            min-width: 180px;
        }

        @media (max-width: $screen-sm) {
            min-width: 0px;
        }
    }
    &__buttons {
        display: flex;
        .button {
            margin-left: 0 !important;
        }
    }
    &__user {
        display: flex;
        font-size: 12px;
        line-height: 17px;
        padding-left: 20px;
        align-items: center;

        &-avatar {
            margin-right: 10px;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            box-sizing: border-box;
            position: relative;
            // svg {
            //     position: absolute;
            //     left: 50%;
            //     top: 50%;
            //     transform: translate(-50%, -50%);
            //     font-size: 16px;
            // }
            
            &--male {
                background: url(../img/avatar-m.png);
            }
            
            &--female {
                background: url(../img/avatar-f.png);
            }

            &--male,
            &--female {
                background-size: 34px 34px;
                background-position: center center;
            }
        }

        &-info {
            &-name {
                display: block;
            }

            &-id {
                display: block;
            }
        }

        &-more {
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #070A0F;
            border-radius: 50%;
            margin-left: 20px;
            cursor: pointer;
            transition: all .2s ease;
        }

        &-menu {
            right: 0;
            min-width: 198px;
            top: calc(100% + 15px);
            position: absolute;
            background: rgba(7, 10, 15, 0.1);
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
            backdrop-filter: blur(70px);
            z-index: 10;
            /* Note: backdrop-filter has minimal browser support */
            transition: all .2s ease;
            visibility: hidden;
            border-radius: 10px;
            padding: 20px;
            opacity: 0;
            
            // min-height: 380px;
            // width: 200px;
            // visibility: hidden;
            // height: 380px;
            // position: absolute;
            // top: 100%;
            // margin-top: 16px;
            // background: rgba(7, 10, 15, 0.1);
            // box-shadow: 0px 5px 20px rgb(0 0 0 / 8%);
            // -webkit-backdrop-filter: blur(70px);
            // backdrop-filter: blur(70px);
            // z-index: 10;
            // transition: all .2s ease;


            // overflow-y: scroll;
            // @include scrollbars(0px, $color-main, #2E3D5C);

            &-link {
                display: flex;
                align-items: center;
                color: #D2D2D2;
                text-decoration: none;
                line-height: 14px;
                padding: 5px 0px 14px;

                &-icon {
                    margin-right: 15px;

                    *[fill="#D2D2D2"], *[fill="white"] {
                        fill: currentColor;
                    }
    
                    *[stroke="#D2D2D2"] {
                        stroke: currentColor;
                    }

                    i {
                        font-size: 1.7em;
                    }
                }

                &:last-child {
                    padding-bottom: 0px;
                }

                &:hover {
                    color: $color-white;
                }
            }
        }

        &--collapsed {
            .header__user-more {
                transform: rotate(180deg);
            }
            .header__user-menu {
                opacity: 1;
                visibility: visible;
            }
        }

        @media (max-width: $screen-xl) {
            &-info {
                &-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 80px;
                }
            }
        }

        @media (max-width: $screen-sm) {
            display: none;
        }
    }

    &__notify {
        display: flex;
        flex-direction: column;
        color: $color-white;
        &-item {
            display: flex;
            &--error {
                color: $color-danger;
            }

            &--success {
                color: $color-success;
            }
        }

        &-icon {
            font-size: 18px;
            margin-right: 10px;
        }

        &-inner {
            display: flex;
            flex-direction: column;
        }
    }

    &__mobile {
        position: relative;
        font-size: 18px;
        text-align: center;
        height: 100%;
        width: calc( 100% + 30px );
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px -15px;

        &-back {
            top: 50%;
            left: 10px;
            position: absolute;
            transform: translateY(-50%);
        }
    }

    &.header--male {
        background-color: $color-male-main;

        .header--fixed {
            background-color: $color-male-main;
        }
        
        .header__mobile {
            background-color: $color-male-main;
        }

        .active {
            .header__menu-link {
                &-text {
                    color: $color-main-male--hover;
                }

                &-icon {
                    path {
                        fill: $color-main-male--hover;
                    }
                }
            }
        }
    }

    .parameters__header {
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% - 10px);
        z-index: 50;
    
        @media (min-width: $screen-sm + 1) {
            display: none;
        }
    }

    .training-control {
        width: 640px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0 0 20px 20px;
        padding-bottom: 20px;

        @media (max-width: $screen-sm) {
            width: 100%;
        }
    }
}