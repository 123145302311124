.channels {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    min-height: 510px;

    &__section {
        padding-top: 50px;
    }
    @media (max-width: $screen-md) {
        border: none;
        overflow: hidden;
        min-height: 0;

        &__section {
            padding-top: 0;
            .container {
                max-width: none;
                padding: 0;
            }
        }
    }

    .section__container {
        max-width: none;
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        background: $color-white;
        border-radius: 10px 0 0 10px;
        border-right: none;
        width: 392px;
        padding: 20px 30px;
        border-right: none;
        @media (max-width: $screen-lg) {
            width: 292px;
        }
        @media (max-width: $screen-md) {
            width: 100%;
            min-height: calc(100vh + 40px);
            padding: 21px 20px;
            border-radius: initial;
            background-color: $color-white;
            &:not(:last-child) {
                display: none;
            }
        }
    }
    &__content {
        flex: 1;
        border-left: 1px solid #ccc;
        position: relative;
    }
    &__title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 14px;
        color: #000;
        @media (max-width: $screen-sm) {
            font-size: 20px;
        }
    }
    &__list {
        @include scrollbars(0px, $color-main, #2e3d5c);

        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 22px 17px;
            background: $color-secondary;
            line-height: 1.4;
            border-radius: 10px;

            .article__header-avatar {
                margin-left: 0;
            }

            &:hover {
                background: $color-main;
                color: $color-white;
            }

            &.active {
                background: $color-main;
                color: $color-white;
            }

            &-avatar {
                border-radius: 50%;
                background-image: url(../img/chat-avatar.png);
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }

            &-info {
                overflow: hidden;
                flex: 1;
                margin-left: 10px;
            }

            &-name,
            &-date {
                color: #000;
                display: block;
            }

            &-name {
                font-size: 13px;
                font-weight: bold;
            }

            &-message {
                color: #000;
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                max-width: 100%;
                margin-top: 2px;
            }

            &-date {
                font-size: 12px;
                margin-bottom: 10px;
            }

            &-count {
                float: right;
                display: inline-block;
                background: #f84469;
                padding: 0 6px;
                line-height: 18px;
                border-radius: 18px;
                font-size: 10px;
            }

            @media (max-width: $screen-sm) {
                padding: 20px 15px;
                &-avatar {
                    min-width: 44px;
                    min-height: 44px;
                }

                &-message {
                    white-space: nowrap;
                }
            }

            @media (max-width: $screen-xs) {
                padding: 20px 10px;
                &-info {
                    margin-right: 0;
                }
            }
        }
    }

    &--male {
        .channels__list-item {
            &:hover {
                background-color: $color-male-main;
            }

            &.active {
                background-color: $color-male-main;
            }
        }

        .channels__list-item {
            background-color: $color-male-secondary;

            &-count {
                background-color: red;
                color: $color-white;
            }

            &-date,
            &-message,
            &-name {
                color: #fff;
            }
        }
    }
}

.channel {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    padding-bottom: 15px;
    min-height: 500px;
    @media (max-width: $screen-md) {
        border-radius: initial;
        padding-bottom: 0;
        min-height: 0;
    }

    &__anchor {
        position: relative;
        border-bottom: 1px solid #ccc;
        padding: 5px 0 5px 40px;
        background-color: $color-white;
        width: 100%;
        cursor: pointer;

        &-title {
            display: block;
            color: red;
            font-size: 16px;
        }

        &-message {
            display: none;
            color: #000;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 0;
            max-height: 40px;
            line-height: 1.5;
            
            &--active {
                display: -webkit-box;
            }
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 3px;
            height: calc(100% - 10px);
            background-color: red;
            top: 5px;
            left: 10px;
        }

        @media (max-width: $screen-sm) {
            padding-left: 12px;

            &-title,
            &-message {
                font-size: 14px;
            }

            &:after {
                left: 2px;
            }
        }
    }

    &__messages {
        flex: 1 1 0%;
        -webkit-flex: 1 1 0%;
        -ms-flex: 1 1 0%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: inline-flex;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        // min-height: 200px;
        background: transparent;
        padding: 0 20px 20px 20px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-bottom: none;

        @include scrollbars(0px, $color-main, #2e3d5c);
        &:first-child {
            padding: 26px 0 23px 0px;
        }

        &:last-child {
            padding: 0px 0px 23px 0px;
        }

        &-date {
            color: #838383;
            text-align: center;
            font-weight: 500;
            padding: 25px 0 14px 0;
            font-size: 13px;

            // &-badge {
            //     position: sticky;
            //     top: 0;
            //     background-color: #1e283d;
            //     color: #607EAF;
            //     padding: 5px;
            //     border-radius: 15px;
            //     margin: 10px auto;
            //     z-index: 2;
            //     max-width: 105px;
            //     text-align: center;
            //     font-weight: normal;
            //     &-holder {
            //         height: 100%;
            //         top: 0;
            //         left: 0;
            //     }
            // }
        }

        &-item {
            display: inline;
            margin-top: 30px;

            &-inner {
                display: flex;
                display: -ms-flexbox;
                display: -webkit-flex;
                flex-wrap: nowrap;
                -webkit-flex-wrap: nowrap;
                -ms-flex-wrap: nowrap;
                flex: 1 1 0%;
                -webkit-flex: 1 1 0%;
                -ms-flex: 1 1 0%;
            }

            &:last-child {
                padding-top: 20px;
            }

            &:first-child {
                margin-bottom: auto;
            }

            & + .dialog__messages-item {
                margin-bottom: 50px;
            }

            &-anchor {
                position: absolute;
                display: block;
                color: #607eaf;
                top: 102%;
                left: calc(100% - 80px);
                cursor: pointer;
            }

            &--me {
                flex-direction: row-reverse;
                -webkit-flex-direction: column-reverse;
                -ms-flex-direction: column-reverse;
                .channel__messages-item-avatar {
                    margin-left: 10px;
                    margin-right: 0;
                }

                .channel__messages-item-text {
                    background: #1e283d;
                    color: #dedede;
                    border-radius: 10px 0px 10px 10px;
                    padding: 15px 12px;
                    line-height: 1.6;
                }

                .channel__messages-item-date {
                    text-align: right;
                }
            }

            &-image {
                float: left;
                width: 80px;
                height: 80px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 5px;
                margin-right: 10px;
            }

            &-avatar {
                width: 44px;
                height: 44px;
                background-repeat: no-repeat;
                background-position: center;
                border: 1px solid #ff0000;
                border-radius: 50%;
                background-color: $color-white;
                margin-right: 10px;

                &--eat_channel {
                    background-image: url(../img/svg/meal.svg);
                }

                &--training_channel {
                    background-image: url(../img/svg/workouts.svg);
                }

                &--info_channel {
                    background-image: url(../img/svg/info.svg);
                }
            }

            &-content {
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex; /* Safari 6.1+ */
                -webkit-flex-wrap: wrap; /* Safari 6.1+ */
                -ms-flex-wrap: wrap;
                flex-wrap: nowrap;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                -webkit-justify-content: space-between; /* Safari 6.1+ */
                justify-content: space-between;
                text-align: left;
                display: flex;
                flex-direction: column;
                position: relative;
                width: 70%;
                background: #e4e4e4;
                border-radius: 0px 10px 10px 10px;
                padding: 5px 30px 5px 12px;

                @media (max-width: $screen-lg) {
                    padding-right: 5px;
                }

                @media (max-width: $screen-sm) {
                    width: 80%;
                }

                &-wrapp {
                    display: flex;
                }
            }

            &-selected {
                .channel__messages-item-content {
                    animation: excretion 1s linear;
                }

                @keyframes excretion {
                    0% {
                        opacity: 0.3;
                    }

                    50% {
                        opacity: 0.5;
                        background-color: #f8e2e2;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }

            &-text {
                flex-direction: column;
                color: #000;
                word-break: break-word;
                margin-left: 10px;

                &-title {
                    font-size: 13px;
                    font-weight: 400;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                &-subtitle {
                    margin-top: 5px;
                    font-size: 13px;
                    color: #7e7e7e;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    white-space: pre-wrap;
                }

                @media (max-width: $screen-sm) {
                    font-size: 13px;
                }
            }

            &-link {
                color: blue;
                text-decoration: underline;
                margin-top: 10px;

                @media (max-width: $screen-sm) {
                    font-size: 13px;
                }
            }

            &-date {
                position: absolute;
                color: #607eaf;
                padding-top: 8px;
                font-size: 12px;
                top: 100%;
            }
        }

        @media (max-width: $screen-md) {
            border: none;
            padding: 0 10px 40px 10px;
        }
    }

    .bottom {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: calc(100% - 70px);
        top: 85%;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $color-white;
        box-shadow: 0 0 5px #ccc;
        cursor: pointer;
        transition: all 0.2s linear;

        &::after,
        &::before {
            content: '';
            background-color: $color-main;
            position: absolute;
            top: 25px;
            display: block;
            width: 20px;
            height: 4px;
            border-radius: 5px;
        }

        &::after {
            transform: rotate(45deg);
            left: 10px;
        }

        &::before {
            transform: rotate(-45deg);
            left: 22px;
        }

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }

    &--male {
        .channel__messages-item-selected {
            .channel__messages-item-content {
                animation: maleExcretion 1s linear;
            }

            @keyframes maleExcretion {
                0% {
                    opacity: 0.3;
                }

                50% {
                    opacity: 0.5;
                    background-color: $color-male-secondary;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        .bottom {
            &::before,
            &::after {
                background-color: $color-male-main;
            }
        }
    }
}
