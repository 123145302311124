.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden scroll;
    outline: 0;
    opacity: 1;
    transition: opacity .15s linear;
    visibility: hidden;
    padding-bottom: 200px;
    scroll-behavior: smooth;
    @include scrollbars(0px, $color-main, #2E3D5C);

    &--show {
        visibility: visible;

        .modal__overlay {
            opacity: 1;
        }

        .modal__dialog {
            opacity: 1;
            margin-top: 0px;
        }
    }

    &__overlay {
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(26, 37, 54, 0.8);
        backdrop-filter: blur(30px);
        transition: all .5s ease;
    }

    &__dialog {
        top: 120px;
        left: calc( 50% - 20px );
        opacity: 0;
        max-width: 460px;
        color: #1A2536;
        position: relative;
        margin: -120px 20px 200px;
        width: calc( 100% - 40px );
        transform: translateX(-50%);
        background: #EEEEEE;
        border-radius: 10px;
        transition: all .5s ease;

        &-header {
            padding: 20px;
            text-align: center;
            display: flex;
            position: relative;
        }

        &-title {
            flex: 1;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            line-height: 135%;
        }

        &-success {
            flex: 1;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            line-height: 135%;
            color: $color-success;
        }

        &-error {
            flex: 1;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            line-height: 135%;
            color: $color-danger;

            &-message {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }

        &-close {
            padding: 3px;
            font-size: 22px;
            cursor: pointer;
            transition: all .2s ease;
            position: absolute;
            top: 50%;
            right: 020px;
            transform: translateY(-50%);

            &:hover {
                color: #000;
            }
        }

        &-body {
            max-width: 360px;
            padding: 0px 20px 40px;
            margin: 0px auto 40px;

            a {
                color: #455F88;
            }

            .timer {
                &__inner {
                    color: #1A2536;
                    background: #fff;
                }
            }
        }

        &-text {
            text-align: center;
            margin-bottom: 15px;
        }

        &-row {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
        }

        &-button {
            border-radius: 5px;
        }

        @media (max-width: $screen-md) {
            top: 50px;
        }
    }
}


