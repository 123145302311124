$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1237px
);

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '~bootstrap-4-grid/scss/grid.scss';

$color-white:                    #fff;
$color-black:                    #000;
$color-gray:                     #606060;
$color-disabled:                 #ccc;
$color-default:                  #323232;
$background-input:               #F8F8F8;

$color-silver--major:            #DCE6E2;
$color-silver--minor:            #979A99;
$color-gold--major:              #FFFB74;
$color-gold--minor:              #FFCC66;
$color-premium--major:           #E9D66B;
$color-premium--minor:           #AC7710;

$color-main:                     #F99494;

$color-female-main:              #F99494;
$color-male-main:                #3b7ac3;
$color-main--hover:              #8b2c04;
$color-main--active:             #7724E1;

$color-main-male--hover:         #ff0000;


$color-secondary:                #f8e2e2;
$color-male-secondary:           #7cb9ff;

$color-primary:                  #77B6FF;
$color-primary--hover:           #5fa9fd;
$color-primary--active:          #5fa9fd;

$color-success:                  #36F05F;
$color-danger:                   #750000;

$color--disabled:                #D1D1D1;

$font-size:                      12px;
$font-size-button:               14px;
$font-size-title:                24px;

$text-color:                     #d2d2d2;
$text-color-title:               #000000;
$text-color--muted:              #828282;

$screen-xs:                      375px;
$screen-sm:                      640px;
$screen-md:                      768px;
$screen-lg:                      992px;
$screen-xl:                      1200px;

$animations:                     1.4s;

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 1.35;
    background-color: $color-white;
    color: $color-white;
    scroll-behavior: smooth;
}   

a {
    transition: all .2s ease;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    &::-webkit-scrollbar {
      width:  $size;
      height: $size;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color;
    }
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
  }

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 36px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    background: #fff9;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

.main-loader {
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-secondary;
    &__wrapper {
        margin: 10% auto;
        color: #fff;
        text-align: center;
        font-size: 6em;
        font-weight: 700;
        letter-spacing: 6px;

        @keyframes logo-animate {
            0%   { opacity: 1; }
            25%   { opacity: 0.5; color: $color-main;}
            50%   { opacity: 1; }
            100% { opacity: 1; }
          }
          &-logo {
            width: max-content;
            @for $i from 1 to 10 {
                span:nth-child(#{$i}) {
                    -webkit-animation: logo-animate 1s infinite;
                    animation:         logo-animate 1s infinite;
                    animation-delay: $i * .1s;
                }
            }
        }
        @media (max-width: $screen-md) {
            width: auto;
            font-size: 3em;
        }
    }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $color-main;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color-main transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.policy {
  color: 'black';
  font-size: 16;

  p {
    margin-bottom: "20px";
    line-height: "1.8em";
  }

  h1,
  h2,
  h3,
  h4 {
    display: block;
    text-align: center
  }

  span {
    width: 100%;
    text-align: right;
    display: block;
    margin-bottom: 50px;
    font-style: italic;
  }

}


@import 'header';
@import 'ui';
@import 'section';
@import 'modal';
@import 'footer';
@import 'tabbar';
@import 'chats';
@import 'progress';
@import 'profile';
@import 'article.scss';
@import 'channels.scss';
@import 'parameters.scss';
@import 'training.scss';
@import 'unsubscribe.scss';
@import 'offert.scss';