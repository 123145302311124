.offert {
    .modal__dialog-body {
        margin-left: 10px;
        margin-right: 10px;
        max-width: initial;
    }

    &__title {
        font-size: 18px;
        text-align: center;
        color: $color-black;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__subtitle {
        color: #FF0000;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
    }

    &__list {
        padding-left: 25px;

        &-item {
            color: $color-black;
            font-size: 16px;
            font-weight: 400;
            list-style: none;
            margin-bottom: 10px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                background: url(../img/svg/offert-mark.svg) center no-repeat;
                display: block;
                left: -28px;
            }
        }
    }

    &__pay {

        &-title {
            text-align: center;
            font-size: 18px;
            text-transform: uppercase;

            span {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 42.73px;
                    height: 2px;
                    display: block;
                    background-color: $color-black;
                    left: 0%;
                    top: 40%;
                    transform: rotate(152.97deg);
                }
            }
        }

        &-subtitle {
            font-size: 11px;
            font-weight: 400;
            text-align: center;
        }

        &-controls {
            padding: 35px;
            background-color: $color-secondary;
            border-radius: 10px; 

            &-button {
                position: relative;
                width: 100%;
                background-color: red;
                border: none;
                color: $color-white;
                font-size: 18px;
                padding: 15px 0;
                border-radius: 5px;
                cursor: pointer;

                &::before,
                &::after {
                    content: '';
                    background-color: $color-white;
                    position: absolute;
                    width: 12px;
                    height: 1px;
                    display: block;
                    left: 90%;
                }
                
                &::before {
                    top: 42%;
                    transform: rotate(45deg);
                }

                &::after {
                    top: 55%;
                    transform: rotate(-45deg);
                }
            }

            .control {
                margin: 30px auto 0 auto;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                label {
                    color: #ABABAB !important;
                    font-size: 13px !important;

                    &::before {
                        min-width: 20px !important;
                        min-height: 20px !important;

                    }
                    svg {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }

    &__policy {
        text-align: center;
        line-height: 20px;

        a {
            color: #00F0FF;
            text-decoration: underline;
        }
    }

    @media (max-width: 460px) {
        &__title {
            font-size: 14px;
        }

        &__subtitle {
            font-size: 13px;
        }

        &__pay {
            &-title {
                font-size: 16px;

                span {
                    &::before {
                        width: 100%;
                    }
                }
            }

            &-controls {
                padding: 15px;

                &-button {
                    padding: 10px 0;
                    font-size: 14px;
                }

                .control {
                    label {
                        font-size: 12px !important;
                    }
                }
            }
        }

        &__list {
            &-item {
                font-size: 14px;
            }
        }

        &__policy {
            font-size: 13px;
        }
    }
}