.training-control {
    position: absolute;
    z-index: 10;
    font-size: 12px;
    padding: 10px 15px 0 15px;
    background: rgba(0, 0, 0, 0.6);

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__title {
        text-align: center;
        display: block;
    }

    &__buttons {
        display: grid;
        justify-content: space-between;
        margin-top: 15px;
        grid-template-columns: 1fr 2fr 1fr;

        &-disable {
            text-decoration: underline;
            cursor: pointer;
            grid-column: 2;
            text-align: center;
        }

        &-prev, 
        &-next {
            text-transform: uppercase;
            cursor: pointer;

            &:after,
            &:before {
                content: '';
                display: block;
                width: 20px;
                height: 4px;
                background-color: $color-white;
                border-radius: 15px;
            }
        }

        &-prev {
            padding-left: 25px;
            position: relative;
            align-self: flex-start;
            grid-column: 1;

            &:after,
            &:before {
                position: absolute;
                left: 0;
            }

            &:after {
                transform: rotate(45deg);
                top: 11px;

            }

            &:before {
                transform: rotate(-45deg);
            }
        }

        &-next {
            padding-right: 20%;
            position: relative;
            align-self: flex-end;
            grid-column: 3;
            text-align: end;

            &:after,
            &:before {
                position: absolute;
                left: 80%;
            }

            &:after {
                transform: rotate(140deg);
                top: 11px;

            }

            &:before {
                transform: rotate(-140deg);
            }
        }
    }
}

.training-pointer {
    pointer-events: none !important;
    position: absolute;
    background: url(../img/svg/pointer.svg) center no-repeat;
    display: block;
    width: 50px;
    height: 50px;
    z-index: 10;
    // animation: animated 0.3s linear;

    // @keyframes animated {
    //     0% {
    //         top: 1000px;
    //     }

    //     100% {
    //         top: 0;
    //         opacity: 1;
    //     }
    // }
}

.training-text {
    pointer-events: none !important;
    max-width: 270px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    position: absolute;
    text-align: center;
    font-size: 12px;
    z-index: 10;
}

.training-arrow {
    pointer-events: none !important;
    position: absolute;
    width: 25px;
    background-color: red;
    height: 3px;
    border-radius: 20px;
    z-index: 10;
    text-align: initial;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 3px;
        background-color: red;
        border-radius: 20px;
    }

    &::before {
        transform: rotate(45deg);
        top: 3px;
    }

    &::after {
        transform: rotate(-45deg);
        top: -3px;
    }
}