.tabbar {
    display: none;
    margin-top: 62px;
    position: relative;

    &__inner {
        position: fixed;
        bottom: 0;
        background: $color-white;
        left: 0;
        width: 100%;
        // height: 62px;
        z-index: 20;
    }

    &__button {
        & > * {
            display: block !important;
            width: calc( 100% - 40px );
            margin: 10px 20px;
        }
    }

    &__menu {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        flex: 1;
        height: 100%;
        border-top: 2px solid #ccc;

        &-link {
            flex: 1;
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 11px 10px 9px;
            flex-direction: column;
            height: 100%;
            color: #000 !important;
            border-bottom: 3px solid transparent;
            position: relative;

            &-notify {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: red;
                color: $color-white;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                font-size: 12px;
                top: 0;
                left: 50%;
            }

            path {
                fill: #000;
            }
            &.active {
                color: red !important;

                path {
                    fill: red;
                }
            }
            
            &-popup {
                opacity: 0;
                width: 100vw;
                visibility: hidden;
                position: absolute;
                top: 100%;
                margin-top: 16px;
                background: rgba(7, 10, 15, 0.1);
                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
                backdrop-filter: blur(70px);
                z-index: 10;
                /* Note: backdrop-filter has minimal browser support */
                transition: all .2s ease;
                border-radius: 10px;
                padding: 20px;
                opacity: 0;

                &--show {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &-icon {
                flex: 1;
                svg {
                    width: auto;
                    height: 24px;
                    max-width: 24px;
                }
                *[fill="#D2D2D2"], *[fill="#A4A4A4"] {
                    fill: currentColor;
                }

                *[stroke="#D2D2D2"] {
                    stroke: currentColor;
                }
            }
            &-text {
                font-size: 10px;
            }

            @media (max-width: 310px) {
                padding: 9px 6px 9px;
            }
        }

        
    }
    @media (max-width: $screen-sm) {
        display: block;
    }

    .training-control {
        position: fixed;
        top: calc(100% - 140px);
        width: 100%;
        height: 73px;
        border-radius: 20px 20px 0px 0px;
    }
}