.section {
    position: relative;
    padding-top: 90px;
    background-color: $color-white;

    @media (max-width: $screen-md) {
        padding-top: 60px;
    }

    &--dark {
        background-color: $color-main;
    }
    &--bright {
        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translateX(50%);
            height: 691px;
            width: 691px;
            background: #B31DF6;
            opacity: 0.1;
            filter: blur(250px);
        }
    }
    &__row {
        margin-top: 15px;
        padding: 30px 0px;
        &--dark {
            background-color: $color-main;
        }
    }
   

    &__title {
        font-size: 38px;
        text-align: center;
        padding-bottom: 15px;
        font-weight: 400;
        max-width: 830px;
        margin: 0 auto;
        &--left {
            max-width: 100%;
            text-align: left;
        }

        @media (max-width: $screen-md) {
            font-size: 32px;
            padding-bottom: 10px;
        }

        @media (max-width: $screen-sm) {
            font-size: 26px;
        }

        @media (max-width: $screen-xs) {
            font-size: 22px;
        }
    }

    &__tabs {
        display: flex;
        justify-content: center;
        &-link {
            width: auto;
            min-width: 186px;
            text-transform: initial;
            font-weight: bold;
            @media (max-width: $screen-xs) {
                min-width: initial;
            }
        }
    }
    &__sub-title {
        font-size: 16px;
        text-align: center;
        max-width: 455px;
        margin: 0 auto;
    }
    &__content {
        padding-top: 15px;

        @media (max-width: $screen-md) {
            padding-top: 10px;
        }
    }

    &__bright {
        position: absolute;
        right: 0;
        top: -50%;
    }
}