.page {
    border: 1px solid #ccc;
    border-radius: 10px;
    max-width: 1216px;
    margin: 50px auto;
    // padding-bottom: 100px;

    &__header {
        position: relative;
        background-color: $color-main;
        padding: 25px 0 25px 45px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 1px solid #e27474;

        &-back {
            position: absolute;
            font-size: 16px;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
        }

    }

    &__title {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        color: $color-white;
    }

    @media (max-width: $screen-md) {
        border: none;
    }

    @media (max-width: $screen-sm) {
        margin: 0;
        border: none;
        &__header {
            display: none;
        }
    }

    &--male {
        .page__header {
            background-color: $color-male-main;
            border-bottom: 1px solid #202cd4;
        }
    }
}

.progress {
    color: #000;

    &__inner {
        padding: 27px 0;
        width: 100%;
    }

    &-button {
        width: 70px;
        height: 70px;
        background: url(../img/svg/plus.svg) center no-repeat;
        border: none;
        cursor: pointer;
    }

    &__value {
        background-color: #f8e2e2;
        text-align: center;
        border-radius: 10px;
        padding: 14px 0;
        font-size: 24px;
        font-weight: 400;
        width: 480px;

        @media (max-width: $screen-xl) {
            width: 400px;
        }

        @media (max-width: $screen-lg) {
            width: 100%;
            font-size: 18px;
        }

        @media (max-width: $screen-md) {
            padding: 10px 0;
            width: 90%;
            font-size: 18px;
        }
    }

    &__results {
        display: flex;
        margin: 0 auto;
        justify-content: center;

        &-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 0 10px;
            &-title {
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                margin-bottom: 12px;
            }
        }

        @media (max-width: $screen-sm) {
            flex-direction: column;
            align-items: center;

            &-item {
                margin-left: 0;
                & + .progress__results-item {
                    margin: 20px 0 0 0;
                }
            }
        }
    }

    &__date {
        width: 100%;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        margin: 45px auto 0 auto;

        &-inner {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-text {
            min-width: 186px;
            margin: 0 20px;
            text-align: center;
        }

        &-next,
        &-back {
            position: relative;
            width: 70px;
            height: 17px;
            border-radius: 10px;
            background-color: #ccc;
            font-size: 10px;
            text-align: center;
            padding: 2px 0 0 7px;
            cursor: pointer;

            &:after,
            &:before {
                content: '';
                background-color: #FF0000;
                width: 8px;
                height: 2px;
                display: block;
                border-radius: 10px;
                position: absolute;
                left: 7px;
            }

            &:before {
                transform: rotate(-45deg);
                top: 5px;
            }

            &:after {
                transform: rotate(45deg);
                top: 10px;
            }

            &:hover {
                box-shadow: 0 0 5px #ccc;
            }

            
        }

        &-next {
            visibility: hidden;
            padding: 2px 7px 0 0;

            &:before {
                transform: rotate(45deg);
                left: calc(100% - 14px)
            }

            &:after {
                transform: rotate(-45deg);
                left: calc(100% - 14px)
            }

            &--active {
                visibility: visible;
            }
        }

        @media (max-width: $screen-sm) {
            font-size: 15px;

            &-text {
                font-size: 13px;
                margin: 0 10px;
                min-width: 140px;
            }
        }

        @media (max-width: 310px) {
            font-size: 13px;
            padding: 0 5px;
            &-text {
                font-size: 11px;
                margin: 0 5px;
                min-width: 110px;
            }
        }
    }


    &__day {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 80%;

        &-loader {
            width: 100%;
            min-height: 50vh;
            padding-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        
            &--male {
              .lds-ring div {
                border: 8px solid $color-male-main;
                border-color: $color-male-main transparent transparent transparent;
              }
            }
          }

        &-body {
            width: 98%;
            margin-top: 31px;
        }

        &-list {
            width: 70%; 
            margin-top: 30px;
            padding-left: 55px;

            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 15px;

                &-delete {
                    position: relative;
                    background-color: red;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: none;
                    margin-left: 15px;
                    cursor: pointer;
    
                    &:after {
                        content: '';
                        width: 25px;
                        height: 4px;
                        border-radius: 20px;
                        background-color: $color-white;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
    
                    @media (max-width: $screen-sm) {
                        width: 30px;
                        height: 30px;
                        margin-left: 10px;
    
                        &:after {
                            width: 15px;
                            height: 3px;
                        }
                    }
                }
            }

            @media (max-width: $screen-sm) {
                width: 100%;
                padding-left: 20px;
            }
        }

        &-calories {
            width: 100%;

            display: flex;
            align-items: center;
            flex-direction: column;

            &-add {
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;

                &-count {
                    width: 100%;
                    padding: 34px 54px;
                    background-color: #F0F0F0;
                    border: 1px solid #B0B0B0;
                    color: #000;
                    outline: none;
                    font-size: 18px;

                    &::placeholder {
                        color: #CFCFCF;
                    }
                }

                &-button {
                    left: 100%;
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    margin-left: 10px;
                    background-size: 70px 70px;
                }
            }

            &-counter {
                margin-top: 20px;  
                font-size: 18px;
                text-align: center;

            }

            @media (max-width: $screen-lg) {
                padding: 0 20px;
                &-add {
                    &-count {
                        font-size: 18px;
                    }
                }
            }

            @media (max-width: $screen-md) {
                padding: 0 35px;

                &-add {

                    &-button {
                        left: 98%;
                        width: 60px;
                        height: 60px;
                        margin-left: 10px;
                        background-size: 50px 50px;
                    }
                }
            }

            @media (max-width: $screen-sm) {
                padding: 0 20px;
                &-add {
                    &-count {
                        font-size: 12px;
                        padding: 10px 14px;
                    }

                    &-button {
                        width: 35px;
                        height: 35px;
                        margin-left: 10px;
                        background-size: 30px 30px;
                    }
                }

                &-counter {
                    font-size: 16px;
                }
            }
        }

        &-exercises {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-top: 40px;
            width: 100%;

            &-title {
                font-size: 24px;
                font-weight: 400;
                text-align: center;
            }

            &-controls {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 18px 0 20px 0;

                &-wrapp {
                    width: 47%;
                    display: flex;
                    flex-direction: column;

                    .error {
                        color: red;
                    }

                    @media (max-width: $screen-sm) {
                        .error {
                            font-size: 12px;
                        }
                    }
                }
            }

            &-time,
            &-practice {
                height: 100px;
                background-color: #F0F0F0;
                border: 1px solid #B0B0B0;
                outline: none;
                color: #000;
                font-size: 18px;
                text-align-last: center; 
                display: block; 
                line-height: 1.3; 
                box-sizing: border-box; 
                margin: 0; 
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url('../img/svg/arrow-down.svg'); 
                background-repeat: no-repeat, repeat;
                background-position: right .7em top 50%, 0 0;
                background-color: #F0F0F0;
                word-wrap: normal;
                text-align: center;

                &::placeholder {
                    text-align: center;
                    font-size: 22px;
                }
            }

            &-practice {
                background-image: url('../img/svg/arrow-down.svg');
            }


            &-time {
                background-image: url('../img/svg/timer.svg');
                background-position: 98% center;
            }

            &-add {
                position: relative;
                background-color: #d8d8d8;
                border: none;
                cursor: pointer;
                margin-top: 30px;

                &-value {
                    text-align: center;
                }

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 35px;
                    height: 35px;
                    background: url(../img/svg/plus.svg) center no-repeat;
                    background-size: 30px 31px;
                    left: calc(100% - 40px);
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover {
                    box-shadow: 0 0 5px #ccc;
                }
            }

            &-result {
                font-size: 21px;
                text-align: center;
                margin-top: 20px;
            }

            @media (max-width: $screen-md) {
                &-practice {
                    background-size: 20px 20px;
                    background-position: 97% center;
                }
            }

            @media (max-width: $screen-sm) {
                &-practice,
                &-time {
                    height: 50px;
                }

                &-time {
                    background-size: 30px 30px;

                    &::placeholder {
                        font-size: 13px;
                    }
                }

                &-practice {
                    background-size: 15px 15px;
                }

                &-result {
                    font-size: 18px;
                    text-align: center;
                }
            }

            @media (max-width: $screen-xs) {
                &-time {

                    &::placeholder {
                        text-align-last: left;
                    }
                }
            }
        }

        @media (max-width: $screen-md) {
            width: 100%;
            padding: 0 20px;
        }
    }

    &--male {
        .progress__value {
            background-color: $color-male-secondary;
        }
    }

}