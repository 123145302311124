.chats {
    display: flex;
    // height: calc(100vh - 219px) !important;
    border: 1px solid #ccc;
    border-radius: 10px;

    &__section {
        padding-top: 50px;
    }
    @media (max-width: $screen-md) {
        border: none;
        overflow: hidden;

        &__section {
            padding-top: 0;
            .container {
                max-width: none;
                padding: 0;
            }
        }
    }

    .section__container {
        max-width: none;
    }

    &__content {
        flex: 1;
        border-left: 1px solid #ccc;
    }
    &__title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 14px;
        color: #000;
        @media (max-width: $screen-sm) {
            font-size: 20px;
        }
    }
    &__list {
        overflow: auto;
        @include scrollbars(0px, $color-main, #2E3D5C);
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 22px 20px;
            background: $color-secondary;
            line-height: 1.4;
            border-radius: 10px;
            &:hover {
                background: $color-main;
                color: $color-white;
            }

            &.active {
                color: $color-white;
                background: $color-main;
            }

            &-avatar {
                border-radius: 50%;
                background-image: url(../img/chat-avatar.png);
                background-size: 100% auto;
                width: 50px;
                height: 50px;
                margin-right: 10px;

                @media (max-width: 310px) {
                    background-repeat: no-repeat;
                    width: 30px;
                    height: 30px;
                }
            }

            &-info {
                overflow: hidden;
                flex: 1;
                margin-left: 10px;
            }

            &-name, &-date {
                color: #000;
                display: block;
            }

            &-name {
                font-size: 14px;
                font-weight: bold;
            }

            &-message {
                color: #000;
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                max-width: 100%;
                margin-top: 2px;
            }


            &-date {
                font-size: 12px;
                margin-bottom: 10px;
            }

            &-count {
                float: right;
                display: inline-block;
                background: #F84469;
                padding: 0 6px;
                line-height: 18px;
                border-radius: 18px;
                font-size: 10px;
            }

            @media (max-width: $screen-sm) {
                padding: 20px 15px;
                &-avatar {
                    min-width: 44px;
                    min-height: 44px;
                }

                &-message {
                    white-space: nowrap;
                }
            }

            @media (max-width: $screen-xs) {
                padding: 20px 10px;
                &-info {
                    margin-right: 0;
                }
            }

            
        }
    }

    &__user {
        background: $color-main;
        border-radius: 0 10px 0 0;
        padding:15px 30px;
        display: flex;
        align-items: center;

        &--mobile {
            display: none;
            text-align: left;
            background: none;
            padding: initial;
            padding: 15px 23px;
            padding-left: 63px;
            .chats__user-avatar {
                margin-left: 10px;
            }
            .chats__user-name {
                font-size: 16px;
            }
            .chats__user-online {
                font-size: 12px;
            }
        }

        @media (max-width: $screen-md) {
            display: flex;
            margin-top: 0;
        }

        &-avatar {
            width: 44px;
            height: 44px;
            background-image: url(../img/chat-avatar.png);
            background-size: 100% auto;

            border-radius: 50%;
            margin-right: 12px;
        }

        &-name {
            font-size: 20px;
        }

        &-online {
            font-size: 12px;
            color: #607EAF;
        }
        
        @media (max-width: $screen-sm) {
            display: none;
            margin-top: 0;
            &--mobile {
                display: flex;
            }
        }
        
        &--male {
            .chats__user-online {
                color: #dfe6fb;
            }
        }
    }
}

.dialog {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: $screen-md) {
        border-radius: initial;
    }

    &__anchor {
        position: relative;
        border-bottom: 1px solid #ccc;
        padding: 5px 0 5px 40px;
        background-color: $color-white;
        width: 100%;
        cursor: pointer;

        &-title {
            display: block;
            color: red;
            font-size: 16px;
        }

        &-message {
            color: #000;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 3px;
            height: calc(100% - 10px);
            background-color: red;
            top: 5px;
            left: 10px;
        }

        @media (max-width: $screen-sm) {
            padding-left: 12px;
            
            &-title ,
            &-message {
                font-size: 14px;
            }

            &:after {
                left: 2px;
            }
        }
    }
    
    &__messages {
        flex: 1 1 0%;
        -webkit-box-flex: 1 1 0%;
        -ms-flex: 1 1 0%;   
        display: -webkit-box;   
        display: inline-flex;
        display: -ms-flexbox;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        // min-height: 200px;
        background: transparent;
        padding: 20px;
        overflow-y: scroll;

        @include scrollbars(0px, $color-main, #2E3D5C);
        &:first-child {
            padding: 26px 0 23px 0px;
        }

        &:last-child {
            padding: 0px 0px 23px 0px;
        }

        &-date {
            position: relative;
            &-badge {
                position: sticky;
                top: 0;
                background-color: transparent;
                color: #607EAF;
                padding: 5px;
                border-radius: 15px;
                margin: 10px auto;
                z-index: 5;
                max-width: 105px;
                text-align: center;
                font-weight: normal;
                font-size: 16px;
                &-holder {
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }

        &-item {
            display: inline;

            &-inner {
                display: -webkit-box;
                display: flex;
                display: -ms-flexbox;
                display: -webkit-flex;
                flex-wrap: nowrap;
                -webkit-flex-wrap: nowrap;
                -ms-flex-wrap: nowrap;
                -webkit-box-flex: 1;
                flex: 1 1 0%;
                -webkit-flex: 1 1 0%;
                -ms-flex: 1 1 0%;
                padding-top: 15px;
            }

            &:first-child {
                margin-bottom: auto;
            }

            &--me {
                .dialog__messages-item-inner {

                    flex-direction: row-reverse;
                    -webkit-flex-direction: column-reverse;
                    -ms-flex-direction: column-reverse;
                    .dialog__messages-item-avatar {
                        margin-left: 10px;
                        margin-right: 0;
                    }
                    
                    .dialog__messages-item-text {
                        background: #dfe6fb;
                        color: #000;
                        border-radius: 10px 0px 10px 10px;
                        padding: 15px 12px;
                        line-height: 1.6;
                    }
                }

                .dialog__messages-item-date {
                    text-align: right;
                }

                .dialog__messages-item-image {
                    text-align: end;
                }
            }
            &-avatar {
                border-radius: 50%;
                width: 40px;
                min-width: 40px;
                background-size: 100% auto;
                height: 40px;
                background-repeat: no-repeat;
                background-position: center;
                margin-right: 10px;
            }

            &-content {
                max-width: 45%;
            }

            &-text {
                background: #e4e4e4;
                color: #000;
                border-radius: 0px 10px 10px 10px;
                padding: 15px 12px;
                line-height: 1.6;
                word-break: break-word;
                @media (max-width: $screen-sm) {
                    font-size: 13px;
                    padding: 12px;
                }
            }

            &-date {
                color: #607EAF;
                padding-top: 8px;
                font-size: 12px;
            }
        }

        @media (max-width: $screen-md) {
            &-item {
                &-content {
                    max-width: 65%;
                }
            }
        }

        @media (max-width: $screen-sm) {
            margin-top: 80px;

            &-item {
                &-content {
                    max-width: 85%;
                }
            }
        }

    }

    &__form {
        background: transparent;
        padding: 20px 30px;
        position: relative;
        border-top: 1px solid #ccc;

        @media (max-width: $screen-lg) {
            &-send {
                border-radius: 10px;
                width: 40px;
                justify-content: center;
                span {
                    display: none;
                }

                svg {
                    margin-left: 0;
                }
            }
        }

        @media (max-width: $screen-sm) {
            padding: 15px 20px;
        }

        &-inner {
            background: #e4e4e4;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            position: relative;
            height: 66px;
            -webkit-overflow-scrolling: touch;
            @include scrollbars(0px, $color-main, #2E3D5C);
        }

        &-icon {
            color: #000;

            &:hover {
                path {
                    color: $color-white;
                    transition: all 0.1s linear;
                }
            }
        }

        &-attach {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 10px;

            &-mark {
                display: none;
                position: absolute;
                background: #F84469;
                padding: 0 8px;
                line-height: 18px;
                border-radius: 18px;
                font-size: 10px;
                top: 0;
                left: 20px;

                @media (max-width: $screen-sm) {
                    top: 10px;
                    left: 10px;
                }
            }

            &.--file {
                .dialog__form-attach-mark {
                    display: block;
                }
            }
        }

        &-emoji-button {
            color: #000 !important;
        }  

        &-message {
            flex: 1 1 0%;
            -webkit-flex: 1 1 0%;
            -ms-flex: 1 1 0%;
            border-radius: 10px;
            outline: none !important;
            color: $color-white;
            overflow: auto;
            padding: 0px 10px;
            line-height: 25px;
            word-break: break-all;
            color: #000;

            @include scrollbars(0px, $color-main, #2E3D5C);
            &:empty:before {
                content: 'Сообщение';
                color: #000;
            }
            &-wrap {
                flex: 1;
                display: flex;
                align-items: center;
                overflow: hidden;
                padding-right: 10px;
            }
        }

        &-emojiPicker {
            position: absolute;
            z-index: 15;
            bottom: 100%;
            right: 0;
            margin-bottom: 10px;
            opacity: 0;
            visibility: hidden;
            transition: all .5s ease;
            &--show {
                opacity: 1;
                visibility: visible;
            }
            
            aside.emoji-picker-react {
                box-shadow: initial;
            }
        }

        .training-control {
            position: absolute;
            display: none;
            top: -80px;
            width: 100%;
            left: 50%;
            height: 80px;
            border-radius: 20px 20px 0 0;
            transform: translateX(-50%);

            @media (max-width: $screen-md) {
                display: block;
            }
        }

        @media (max-width: 310px) {
            &-message {
                font-size: 11px;
            }
        }
    }

    &--male {
        .chats__user {
            background-color: $color-male-main;


            &-online {
                color: #dfe6fb;
            }
        }
    }
}

.LazyLoad.is-visible {
    max-width: 45%;
}