.profile {
    &__header {
        position: relative;
        background-color: $color-main;
        padding: 8px 0;

        @media (max-width: $screen-sm) {
            position: relative;
            height: 200px;
            background: url(../img/female/bg.svg);
            background-repeat: no-repeat;
            background-size: 100% 160%;
            background-position: center 0px;
        }
    }

    &__info {
        display: flex;
        align-items: center;

        &-avatar {
            position: relative;
            width: 170px;
            height: 170px;
            margin: 0 20px;
            border-radius: 50%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 170px 170px;
            pointer-events: none;

            input {
                display: none;
            }

            &:after {
                content: '';
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background: url(../img/svg/camera.svg) center no-repeat, #f8e2e2d3;
                border-radius: 50%;
                background-size: 30px 30px;
                transition: all 0.2s linear;
            }
        }

        &-list {
            width: 40%;
        }

        &-name {
            color: $color-white;
            font-size: 36px;
        }

        @media (max-width: $screen-sm) {
            width: 100%;
            flex-direction: column;
            position: absolute;
            top: 5%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;

            &-list {
                margin-top: 7px;
                width: 90%;
            }

            &-name {
                color: $color-black;
                font-size: 24px;
            }
        }
    }

    &__inner {
        padding: 0 60px 60px 60px;

        @media (max-width: $screen-lg) {
            padding: 0 50px 92px 50px;
        }

        @media (max-width: $screen-md) {
            padding: 0 15px 30px 15px;
        }

        @media (max-width: 310px) {
            padding: 0 5px 30px 5px;
        }
    }

    &__body {
        margin-top: 73px;
        padding: 0 0 60px;
        display: flex;

        

        @media (max-width: $screen-md) {
            flex-direction: column;
            align-items: center;
            margin-top: 60px;
            padding: 0 15px;
        }
    }

    &__button {
        width: 30%;
        margin-bottom: 30px;
        @media (max-width: $screen-md) {
            margin-top: 20px;
            width: 100%;
        }
    }

    &__proportions {
        width: 60%;

        &-item {
            position: relative;
            background-color: #f8e2e2;
            background-position: 5% center;
            background-repeat: no-repeat;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 48px;
            padding: 0 10px 0 45px;
            color: #000;
            font-size: 24px;

            & + .profile__proportions-item {
                margin-top: 10px;
            }

            &-edit {
                background-image: url(../img/svg/pencil.svg);
                border: none;
                width: 19px;
                height: 19px;
                cursor: pointer;
                background-color: transparent;
            }

            &-input {
                width: 55%;
                display: none;
                border: none;
                border-radius: 10px;
                outline: none;
                padding: 10px 15px;
                font-size: 20px;
                text-align: center;
            }

            &-placeholder {
                text-align: center;
                color: #ccc;
            }
            
            &-select {
                -webkit-appearance: none;                
                -moz-appearance: none;
                background-color: #f8e2e2;
                outline: none;
                border: none;
                width: 100%;
                text-align-last: center;
            }
        }
            
        @media (max-width: $screen-md) {
            width: 100%;

            &-item {
                font-size: 20px;
            }   
        }

        @media (max-width: $screen-sm) {
            &-item {
                &-placeholder {
                    font-size: 16px;
                }

                font-size: 18px;
            }
        }

        @media (max-width: 310px) {
            &-item {
                &-placeholder {
                    font-size: 13px;
                }

                font-size: 14px !important;

                .react-select__single-value {
                    font-size: 14px !important;
                }
            }
        }
    }

    &--male {
        .profile__header, 
        .profile__norm-value {
            background-color: $color-male-main;
        }

        .profile__norm {
            &-value {
                background-image: url(../img/male/kcal.svg);
            }
    
            &-value {
                color: $color-white;
            }
        }

        .profile__header {
            @media (max-width: $screen-sm) {
                position: relative;
                height: 200px;
                background: url(../img/male/bg.svg);
                background-repeat: no-repeat;
                background-size: 100% 160%;
                background-position: center 0px;
            }
        }

        .profile__proportions { 
            @for $i from 1 through 5 {
                div:nth-child(#{$i}) {
                    .profile__proportions-item-select {
                        background: url(../img/male/#{$i}.svg) 2% center no-repeat, 
                        url(../img/svg/pencil.svg) 98% center no-repeat,
                        $color-male-secondary;

                        background-size: 15% 70%, auto, auto;
                    }
                } 
            }
        }

        .profile__info-avatar {
            &:after {
                content: '';
                display: block;
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background: url(../img/svg/camera.svg) center no-repeat, #3b7bc3d5;
                border-radius: 50%;
                background-size: 30px 30px;
                transition: all 0.2s linear;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &--female {
        .profile__proportions {
            @for $i from 1 through 5 {
                div:nth-child(#{$i}) {
                    .profile__proportions-item-select {
                        background: url(../img/female/#{$i}.svg) 2% center no-repeat, 
                        url(../img/svg/pencil.svg) 98% center no-repeat,
                        $color-secondary;

                        background-size: 15% 70%, auto, auto;
                    }
                } 
            }
        }
    }

    &__norm {
        margin: 40px 0 10px 50px;
        width: 40%;
        text-align: center;
        color: #000;
        display: flex;
        flex-direction: column;
        
        &-title {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }

        &-value {
            margin: 16px auto 0 auto;
            width: 85%;
            padding: 32px 35px 32px 100px;
            background-image: url(../img/female/kcal.svg);
            background-position: 10% center;
            background-repeat: no-repeat;
            background-color: #f8e2e2;
            font-size: 36px;
            font-weight: 300;
            border-radius: 10px;
        }

        @media (max-width: $screen-md) {
            width: 70%;
            margin: 30px 0 0 0;

            &-value {
                text-align: center;
            }
        }

        @media (max-width: $screen-sm) {
            width: 100%;

            &-value {
                padding-left: 2.5em;
                background-size: 50px 50px;
            }
        }

        @media (max-width: 310px) {
            &-value {
                padding-left: 2.5em;
                font-size: 28px;
            }
        }
    }

    .parameters__profile {
        top: 10px;
        left: calc(100% - 40px);
    
        @media (max-width: $screen-sm) {
            display: none;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        height: 100px;
        padding: 30px 60px 0 0;
        margin-bottom: 70px;
        align-items: flex-end;

        .profile__button {
            width: 30%;
            & + .profile__button {
                margin: 10px 0 0 0;
            }
        }

        @media (max-width: $screen-md) {
            align-items: center;
            padding-right: 0;
            .profile__button {
                width: 85%;    
            }
        }
    }

    &__registration,
    &__login {
        background: linear-gradient(0deg, #FF2A7D 0%, #FE4282 14.43%, #FC6489 38.53%, #FA7C8F 61.48%, #F98B92 82.55%, #F99093 100%);
        min-height: 700px;
        border-radius: 5px;
        padding: 141px 90px 105px 90px;

        &-title {
            text-transform: uppercase;
            font-size: 48px;
            font-weight: 400;
            margin: 0;
            text-align: center;
        }

        @media (max-width: $screen-sm) {
            padding: 100px 10px 60px 10px;
            height: calc(100vh - 57px);
            border-radius: 0;

            &-title {
                font-size: 38px;
            }
        }
    }

    &__registration {
        padding-top: 70px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-input.control {
            width: 100%;
            
            input {
                padding: 18px 31px;
                border-radius: 10px;
                color: #A8A8A8;
                font-size: 24px;
                margin-top: 30px;
                background-color: $color-white;
            }
        }

        &-button {
            width: 40%;
            background-color: #F99494;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-pass {
            color: $color-white;
            font-size: 18px;
            margin-top: 15px;
            text-align: center;

        }

        &-register {
            margin-top: 46px;
            font-size: 24px;
            text-align: center;

            a {
                text-decoration: underline;
            }
        }

        @media (max-width: $screen-sm) {
            &-register {
                font-size: 20px;
            }
        }
    }

    &__change {
        
        &-inner {
            padding: 40px 0 60px 68px;
        }
        &-title {
            color: $color-black;
            font-size: 36px;
            font-weight: 400;
        }

        &-form {
            display: flex;
            justify-content: space-between;  
            flex-direction: column; 
            align-items: flex-start;

            &-inputs {
                width: 65%;

                .control {
                    input {
                       background-image: url(../img/svg/pencil.svg);
                       background-repeat:  no-repeat;
                       background-position: calc(100% - 20px) center;
                       font-size: 24px;
                       font-weight: 400;
                       font-family: 'Rubik', sans-serif;
                       padding: 25px 21px;
                       border-radius: 10px;
                    }
                }
            }

        }

        &-button {
            min-width: 258px;
        }

        @media (max-width: $screen-sm) {
            .profile__header {
                display: none;
            }
            &-inner {
                padding: 30px 25px;
                text-align: center;
            }

            &-title {
                font-size: 22px;
            }

            &-form {
                align-items: center;

                &-inputs {
                    width: 100%;

                    .control {
                        input {
                           padding: 15px 21px;
                           font-size: 18px;
                        }
                    }
                }
            }

            &-button {
                padding: 20px 75px;
                margin: 10px 0 0 0;
                min-width: 224px;
            }
        }
    }
}

.react-select__menu-list::-webkit-scrollbar {
    width: 5px;
    height: 0px;
}
.react-select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;

}
.react-select__menu-list::-webkit-scrollbar-thumb {
    background: #8b2c04;
    border-radius: 10px;

}
.react-select__menu-list::-webkit-scrollbar-thumb:hover {
    background: #8b2c04;
}

@media (max-width: 310px) {
    .react-select__single-value {
        font-size: 13px !important;
        padding: 0 10px;
    }
}