.footer {
    background-color: $color-main;
    margin-top: 90px;
    padding-top: 46px;
    padding-bottom: 25px;

    &__info {
        font-size: 16px;

        &-item {
            display: block;
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    @media (max-width: $screen-md) {
        // margin-top: 40px;
        &--mobile-hide {
            display: none;
        }
    }

    &__col {
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        &-text {
            padding-top: 15px;
            line-height: 1.6;
        }
        p {
            margin: 0;
        }
    }
    &__menu {
        display: flex;
        flex-direction: column;
        height: 100%;
        &-link {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        @media (max-width: $screen-sm) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: initial;
            &-link {
                display: initial;
                margin-bottom: 6px;
                padding-right: 17px;
            }
        }
    }
    &__phone {
        font-size: 24px;
    }

    &__contact {
        margin-top: 10px;
        &-title {
            color: #B7B7B7;
            margin-bottom: 5px;
        }
        &-body {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            color: $color-white;
            svg {
                color: #954CF2;
                font-size: 20px;
                margin-right: 14px;
            }
            &-info {
                padding-bottom: 20px;
            }
        }

    }

    &__social {
        margin-top: 27px;
        &-link {
            margin-right: 19px;
            background-color: $color-main;
            width: 37px;
            display: inline-block;
            height: 37px;
            text-align: center;
            border-radius: 50%;
            line-height: 42px;
            svg {
                font-size: 19px;
                color: $color-white;
            }
        }
    }

    &--male {
        background-color: $color-male-main;
    }
}

