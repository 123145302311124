a {
    cursor: pointer;
    color: $color-white;
    text-decoration: none;
    transition: all .2s ease;

    &:visited {
        // color: currentColor;
    }

    &:hover {
        color: $color-main--hover;
    }

    &.active {
        color: $color-main--hover;
    }

    &.underline {
        text-decoration: underline;
    }
}

.button, a.button {
    width: 100%;
    border: none;
    display: block;
    cursor: pointer;
    border-radius: 10px;
    padding: 19px 32px 18px;
    font-size: $font-size-button;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    transition: all .2s ease;
    outline: none !important;

    @media screen and (max-width: $screen-xl) {
        &:not(.button--small):not(.button--large) {
            padding: 16px 26px;
        }
    }
    @media screen and (max-width: $screen-sm) {
        &:not(.button--small):not(.button--large) {
            padding: 16px 26px;
        }
        span {
            font-size: 12px;
        }
    }

    & + .button {
        margin-left: 20px;
    }

    & + small {
        color: #455F88;
        display: block;
        font-size: $font-size;
        line-height: 22px;
        margin-top: 10px;
        text-align: center;
        border-bottom: 1px solid #F1F1F1;
        @media screen and (max-width:$screen-sm) {
            font-size: 13px;
            line-height: 1.34;
        }
        @media screen and (max-width:$screen-xs) {
            font-size: 11px;
            line-height: 1.34;
        }
    }

    &--main {
        background-color: $color-main;

        &:hover {
            background-color: $color-main--hover;
        }

        &:active {
            background-color: $color-main--hover;
        }

        &--male {
            background-color: $color-male-main;
        }
    }

    &--primary {
        background-color: $color-primary;

        &:hover {
            background-color: $color-primary--hover;
        }

        &:active {
            background-color: $color-primary--hover;
        }
    }

    &--transparent {
        background-color: transparent;
        color: $text-color !important;

        &:hover {
            background-color: transparent;
            color: $color-white !important;
        }

        &:active {
            background-color: transparent;
        }
    }

    &--outline {
        &-main {
            color: $color-white;
            background-color: transparent;
            border: 1px solid rgba($color-main, .25);

            &:hover {
                color: $color-white;
                border-color: $color-main--hover;
            }
            &.active {
                background-color: $color-main;
            }
        }

        &-primary {
            color: $color-primary;
            background-color: transparent;
            border: 1px solid $color-primary;

            &:hover {
                color: #fff;
                border-color: $color-primary--hover;
                background-color: $color-primary--hover;
            }
        }
    }

    &--bordered {
        background-color: transparent !important;
        border: 1px solid #F1F1F1;

        &:hover {
            border-color: currentColor;
        }
    }

    &--small {
        width: auto;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 14px 32px;
        font-size: $font-size-button * 0.75;
        span {
            font-weight: 700;
        }
        @media (max-width: $screen-xs) {
            padding: 10px 9px;
        }
    }

    &--large {
        width: auto;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 86px 28px;
        font-size: $font-size-button;

        span {
            font-weight: 700;
            text-align: center;
        }
        @media (max-width: $screen-xs) {
            padding: 20px 12px;
        }
    }

    &--icon {
        padding: 0;

        svg {
            margin: 0px !important;
            font-size: 20px !important;
        }
    }

    &[disabled], &--disabled {
        cursor: default;
        background-color: $color--disabled;
    }

    i {
        font-size: 1.1rem;
    }

    &--icon-right {
        svg {
            margin-left: 12px;
            margin-right: 0px;
        }
    }
}

.control {
    position: relative;
    margin-bottom: 12px;

    label {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 135%;
        margin-bottom: 4px;
    }

    &__message {
        font-size: 14px;
        margin-top: 5px;
        display: block;
        color: $color-white;
    }

    & + .control, & + .button {
        margin-top: 3px;
    }

    select,
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    textarea {
        width: 100%;
        border: none;
        display: block;
        border: 1px solid #CBCBCB;
        border-radius: 5px;
        padding: 13px;
        font-weight: bold;
        font-size: $font-size;
        text-decoration: none;
        background-color: $color-main;
        color: #777;
        resize: none;

        &:active, &:focus {
            outline: none;
            // color: #1A2536;
        }
    
        &::placeholder {
            color: $text-color--muted;
        }

        // @media screen and (max-width:$screen-sm) {
        //     padding: 23px 15px 24px;
        //     font-size: 12px;
        // }

        // @media screen and (max-width:$screen-xs) {
        //     padding: 23px 15px 24px;
        //     font-size: 12px;
        // }
    }

    input[type="radio"],
    input[type="checkbox"] {
        display: none;

        & + label {
            display: flex;
            align-items: center;
            position: relative;
            font-weight: normal;
            margin-bottom: 0px;

            &:before {
                content: '';
                width: 20px;
                height: 20px;
                margin-right: 8px;
                border-radius: 50%;
                position: relative;
                display: inline-block;
                background-color: transparent;
                border: 1px solid #ffffff;
                transition: all .35s ease;
            }

            &:hover {
                &:before {
                    border-color: $color-main;
                }
            }

            &:empty {
                &:before {
                    margin-right: 0px;
                }
            }
        }

        &:checked {
            & + label {
                &:before {
                    border-color: $color-main;
                    background-color: $color-main;
                    box-shadow: inset 0px 0px 0px 4.5px #10151f;
                }
            }
        }
    }

    input[type="checkbox"] {
        & + label {
            cursor: pointer;
            color: #455F88;
            align-items: flex-start;

            svg {
                position: absolute;
                top: 3px;
                font-size: 8px;
                left: 3px;
                display: none;
            }

            &:before {
                width: 14px;
                height: 14px;
                min-width: 14px;
                border: 1px solid #CBCBCB;
                border-radius: 2px;
            }
        }

        &:checked {
            & + label {
                &:before {
                    color: #fff;
                    content: "";
                    box-shadow: none;
                    font-size: 8px;
                    text-align: center;
                    line-height: 14px;
                    // font-family: "Font Awesome 5 Pro";
                    font-weight: 700;
                }

                svg {
                    display: block;
                    color: $color-white;
                }
            }
        }
    }

    &--error, &--success {

        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"] {
            padding-right: 78px;
            border: 2px solid $color-danger ;
        }

        // &:after {
        //     color: #fff;
        //     top: 50%;
        //     right: 13px;
        //     display: block;
        //     width: 20px;
        //     height: 20px;
        //     line-height: 19px;
        //     text-align: center;
        //     border-radius: 50%;
        //     position: absolute;
        //     transform: translateY(-50%);
        //     font-family: 700;
        //     font-size: 12px;
        // }
    }

    &--error {
        input, select {
            border-color: $color-danger !important;
        }

        // &:after {
        //     content: "x";
        //     background-color: $color-danger;
        // }

        span {
            color: $color-danger;
        }
    }

    &--success {
        input, select {
            border-color: $color-success !important;
        }

        &:after {
            content: "\F00C";
            background-color: $color-success;
        }

        span {
            color: $color-success;
        }
    }

    &--male {
        select,
        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        textarea {
            background-color: $color-male-main;
            color: $color-white;

            &::placeholder {
                color: #bdbdbd;
            }
        }
    }
}