.unsubscribe {
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        
        .button {
            width: 100%;
        }
    }
}