.parameters {
    position: absolute;

    &__inner {
        position: relative;
    }

    &__open {
        position: relative;
        display: block;
        width: 20px;
        height: 40px;
        cursor: pointer;

        span,
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 7px;
            height: 7px;
            display: block;
            background-color: $color-white;
            border-radius: 50%;
            left: 50%;
            transform: translate(-50%);
        }

        &:before {
            top: 0;
        }

        span {
            top: 13px;
        }

        &:after {
            top: 26px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        width: 342px;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        background-color: $color-white;
        border-radius: 10px;
        top: 0px;
        right: 20%;
        padding: 0;
        transition: all 0.2s linear;
        box-shadow: 0 0 5px #ccc;

        &-item {
            cursor: pointer;
            list-style: none;
            color: #000;
            font-size: 18px;
            padding: 15px 30px;
            color: $color-black;
        }

        &--active {
            visibility: visible;
            opacity: 1;
        }

        @media (max-width: $screen-sm) {
            width: 240px;
            &-item {
                font-size: 14px;
                padding: 10px;
            }
        }

        @media (max-width: 310px) {
            width: 210px;
            &-item {
                font-size: 14px;
                padding: 10px;
            }
        }
    }
}
