.article {
    color: #000;
    width: 100%;
    position: relative;


    li {
        line-height: 2 !important;
        // font-family: "Rubik", sans-serif !important;
        white-space: normal !important;
    }

    span {
        display: block;
        max-width: 100% !important;
        height: auto !important;
        overflow: auto;
    }

    // ul {
    //     li {
    //         font-weight: 400 !important;
    //     }
    // }

    ol {
        li {
            // font-size: 24px !important;
            // font-weight: 400 !important;
            // margin-bottom: 20px;

            span {
                display: block;
                // font-size: 24px !important;
                // font-weight: 400 !important;
                // padding-left: 40px;
            }
        }
    }

    &__photo {
        height: auto !important;
    }

    &__photo,
    &__video {
        max-width: 100% !important;
    }
    
    &__header {
        background: $color-main;
        border-radius: 0 10px 0 0;
        padding: 15px 15px 15px 30px;
        display: flex;
        align-items: center;

        &--mobile {
            display: none;
            text-align: left;
            background: none;
            padding: initial;
            padding: 15px 23px;
            padding-left: 63px;
        }

        &-back {
            position: absolute;
            left: 20px;
            font-size: 18px;
        }

        &-avatar {
            width: 44px;
            height: 44px;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid #ff0000;
            border-radius: 50%;
            background-color: $color-white;
            margin-left: 20px;

            &--eat_channel {
                background-image: url(../img/svg/meal.svg);
            }

            &--training_channel {
                background-image: url(../img/svg/workouts.svg);
            }

            &--info_channel {
                background-image: url(../img/svg/info.svg);
            }

            // @media (max-width: 310px) {
            //     width: 61px;
            //     height: 45px;
            //     margin-left: 10px;
            // }
        }

        &-info {
            margin-left: 10px;
        }

        &-date {
            color: $color-white;
            font-weight: 700;
        }

        @media (max-width: $screen-sm) {
            &-date {
                font-size: 13px;
            }

            &--mobile {
                display: flex;
            }
        }
    }

    &__header--male {
        background: $color-male-main;
    }

    &__list {
        width: 100%;
        padding: 0 20px;
        
        &-item {
            &-title {
                font-size: 24px;
                font-weight: 500;
                text-transform: uppercase;
            }

            &-decription {
                font-size: 24px !important;
                text-align: center !important;
                font-weight: 400 !important;
                padding: 0 20px !important;
            }

            &-photo {
                width: 100%;
            }

            @media (max-width: $screen-sm) {
                &-decription {
                    font-size: 18px;
                }
            }
        }
    }

    &__characteristics {
        width: 100%;
        list-style: disc;
        margin-top: 50px;

        &-item {
            font-size: 21px;
            font-weight: 400;

            &-wrapp {
                display: flex;
            }

            & + .food__characteristics-item {
                margin-top: 10px;
            }
        }

        @media (max-width: $screen-sm) {
            &-item {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__coocking {
        display: block;
        color: #ff0000;
        font-size: 21px;
        font-weight: 400;
        text-align: center;

        @media (max-width: $screen-sm) {
            font-size: 18px;
        }
    }

    &__ingredients {
        width: 100%;
        padding: 0 40px;
        margin-top: 20px;

        &-item {
            font-size: 21px;
            &-wrapp {
                display: flex;
                justify-content: space-between;
                padding: 5px 10px;
                font-size: 20px;
            }

            & + .food__ingredients-item {
                margin-top: 10px;
            }
        }
    }

    &__stages {
        width: 100%;
        margin-top: 20px;
        padding: 0 20px;
        font-size: 21px;

        &-image {
            width: 100%;
        }

        &-title {
            font-size: 24px;
            text-align: center;
        }

        &-list {
            list-style-type: decimal;
            margin-top: 10px;
        }

        &-item {
            &-text {
                border-radius: 10px;
                font-size: 24px;
                font-weight: 400;
            }

            &-text {
                margin-left: 20px;
            }

            & + .food__stages-item {
                margin-top: 20px;
            }
        }

        @media (max-width: $screen-lg) {
            font-size: 18px;
            &-item {
                &-text {
                    font-size: 18px;
                }
            }
        }
    }

    &__content {
        padding: 0 20px;
        font-size: 24px;

        @media (max-width: $screen-lg) {
            font-size: 18px;
        }

        @media (max-width: $screen-md) {
            font-size: 14px;
        }
    }

    &__title {
        font-size: 24px;
        margin-left: 10px;
        font-weight: 500;

        li {
            span {
                font-weight: 500;
                font-size: 24px;
                line-height: 0;
            }

            &::marker {
                font-weight: 500;
                font-size: 24px;
            }
        }
    }

    &__description {
        text-align: center;
        margin: 0;
    }

    &__list-1 {
        padding: 10px 60px 0 60px;

        span {
            font-size: 21px;
            font-weight: 400;
        }

        li {
            font-size: 16px;
            margin-bottom: 5px;
        }
    }

    &__time {
        padding: 0 40px;

        span {
            font-size: 21px;
            color: red;
        }
    }

    &__list-2 {
        padding: 0 60px;

        span {
            font-size: 21px;
            font-weight: 400;
        }

        li {
            font-size: 16px;
            margin-bottom: 5px;

            div {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &__list-3 {
        padding: 0 60px;

        span {
            font-size: 24px;
            font-weight: 400;
            display: block;
            padding-left: 40px;
        }

        li {
            font-size: 24px;
            margin-bottom: 5px;
            margin: 10px 0 40px 0;
        }
    }

    @media (max-width: $screen-lg) {
        &__content {
            padding: 0 10px;
            font-size: 21px;
        }

        &__time,
        &__list-1,
        &__list-2,
        &__list-3 {
            padding: 0 10px;
            margin-left: 20px;
            
            span {
                font-size: 18px;
            }
        }

        &__time {
            margin-left: 0;
        }
    }

    @media (max-width: $screen-sm) {
        .article__content {
            padding: 0;
            font-size: 16px;
        }

        .article__time,
        .article__list-1,
        .article__list-2,
        .article__list-3 {
            span {
                font-size: 16px;
            }
        }
    }

    &__loader {
        width: 100%;
        padding-top: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        &--male {
            .lds-ring div {
                border: 8px solid $color-male-main;
                border-color: $color-male-main transparent transparent
                    transparent;
            }
        }
    }
}
